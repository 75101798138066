/*container property */
.container {
  padding: 0 15px;
}
/* display property */
.d-none {
  display: none !important;
}
/*grid */
/* row */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.text-center {
  text-align: center;
}
/*grid */
.col-sm-6 {
  max-width: 50%;
  flex: 0 0 50%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
.col-md-12 {
  max-width: 100%;
  flex: 0 0 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
.offset-md-1 {
  margin-left: 8.333%;
}
.offset-md-2 {
  margin-left: 16.666%;
}
.offset-md-3 {
  margin-left: 25%;
}
.col-md-8 {
  max-width: 66.66%;
  flex: 0 0 66.66%;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.col-md-7 {
  max-width: 58.333%;
  flex: 0 0 58.333%;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.col-md-6 {
  max-width: 50%;
  flex: 0 0 50%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
.col-md-4 {
  max-width: 33.333333%;
  flex: 0 0 33.333333%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 575px) {
  .col-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .col-1 {
    max-width: 10%;
    flex: 0 0 10%;
  }

  .col-2 {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .col-3 {
    max-width: 30%;
    flex: 0 0 30%;
  }

  .col-4 {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
}